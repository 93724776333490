import { default as financingLch38sQ1iCMeta } from "/codebuild/output/src3487572758/src/flintrock/nuxtapp/pages/about-us/financing.vue?macro=true";
import { default as indexVg9jqH3GpUMeta } from "/codebuild/output/src3487572758/src/flintrock/nuxtapp/pages/about-us/index.vue?macro=true";
import { default as our_45storyUi1ob682cnMeta } from "/codebuild/output/src3487572758/src/flintrock/nuxtapp/pages/about-us/our-story.vue?macro=true";
import { default as our_45teamnhmaUsiRPsMeta } from "/codebuild/output/src3487572758/src/flintrock/nuxtapp/pages/about-us/our-team.vue?macro=true";
import { default as copperas_45cove_45areazcqWI9BaVXMeta } from "/codebuild/output/src3487572758/src/flintrock/nuxtapp/pages/areas/copperas-cove-area.vue?macro=true";
import { default as georgetown_45areaqBqChZhXvcMeta } from "/codebuild/output/src3487572758/src/flintrock/nuxtapp/pages/areas/georgetown-area.vue?macro=true";
import { default as killeen_45areaDlPyRGcZ9TMeta } from "/codebuild/output/src3487572758/src/flintrock/nuxtapp/pages/areas/killeen-area.vue?macro=true";
import { default as lorena_45areaame1PvNZxIMeta } from "/codebuild/output/src3487572758/src/flintrock/nuxtapp/pages/areas/lorena-area.vue?macro=true";
import { default as nolanville_45areaqX69W7q4WcMeta } from "/codebuild/output/src3487572758/src/flintrock/nuxtapp/pages/areas/nolanville-area.vue?macro=true";
import { default as salado_45aread7Zz76U9PwMeta } from "/codebuild/output/src3487572758/src/flintrock/nuxtapp/pages/areas/salado-area.vue?macro=true";
import { default as temple_45areaLkPGpbcBoOMeta } from "/codebuild/output/src3487572758/src/flintrock/nuxtapp/pages/areas/temple-area.vue?macro=true";
import { default as troy_45areaROjGTR37MXMeta } from "/codebuild/output/src3487572758/src/flintrock/nuxtapp/pages/areas/troy-area.vue?macro=true";
import { default as _91post_93aeQvqcA2D1Meta } from "/codebuild/output/src3487572758/src/flintrock/nuxtapp/pages/blog/[post].vue?macro=true";
import { default as indexSGvduzIJCLMeta } from "/codebuild/output/src3487572758/src/flintrock/nuxtapp/pages/blog/category/[category]/index.vue?macro=true";
import { default as indexgj56uw0vgAMeta } from "/codebuild/output/src3487572758/src/flintrock/nuxtapp/pages/blog/index.vue?macro=true";
import { default as _91_46_46_46slug_93T0pWupx7jIMeta } from "/codebuild/output/src3487572758/src/flintrock/nuxtapp/pages/campaigns/[...slug].vue?macro=true";
import { default as indexOQGPN9ixVmMeta } from "/codebuild/output/src3487572758/src/flintrock/nuxtapp/pages/campaigns/index.vue?macro=true";
import { default as park_45meadowscWfR35jdgaMeta } from "/codebuild/output/src3487572758/src/flintrock/nuxtapp/pages/coming-soon/park-meadows.vue?macro=true";
import { default as rosenthal_45estatesx8xaYJj0M4Meta } from "/codebuild/output/src3487572758/src/flintrock/nuxtapp/pages/coming-soon/rosenthal-estates.vue?macro=true";
import { default as templateMORIUdLbLZMeta } from "/codebuild/output/src3487572758/src/flintrock/nuxtapp/pages/coming-soon/template.vue?macro=true";
import { default as indexY0zVfSSK4qMeta } from "/codebuild/output/src3487572758/src/flintrock/nuxtapp/pages/communities/[community]/index.vue?macro=true";
import { default as ask_45a_45question117cVvTcl6Meta } from "/codebuild/output/src3487572758/src/flintrock/nuxtapp/pages/contact-us/ask-a-question.vue?macro=true";
import { default as career_45opportunitiesjU4gjz4wIRMeta } from "/codebuild/output/src3487572758/src/flintrock/nuxtapp/pages/contact-us/career-opportunities.vue?macro=true";
import { default as faq06wp9TX2HeMeta } from "/codebuild/output/src3487572758/src/flintrock/nuxtapp/pages/contact-us/faq.vue?macro=true";
import { default as homeowner_45resourcespXgfkYre8BMeta } from "/codebuild/output/src3487572758/src/flintrock/nuxtapp/pages/contact-us/homeowner-resources.vue?macro=true";
import { default as indexzIohZMVSqDMeta } from "/codebuild/output/src3487572758/src/flintrock/nuxtapp/pages/contact-us/index.vue?macro=true";
import { default as our_45locationsAZPHiuEhBHMeta } from "/codebuild/output/src3487572758/src/flintrock/nuxtapp/pages/contact-us/our-locations.vue?macro=true";
import { default as trade_45partner_45inquiryb47oXkgAWdMeta } from "/codebuild/output/src3487572758/src/flintrock/nuxtapp/pages/contact-us/trade-partner-inquiry.vue?macro=true";
import { default as current_45offers9PV39XRtcHMeta } from "/codebuild/output/src3487572758/src/flintrock/nuxtapp/pages/current-offers.vue?macro=true";
import { default as indexXBGicUgM2HMeta } from "/codebuild/output/src3487572758/src/flintrock/nuxtapp/pages/events/[event]/index.vue?macro=true";
import { default as index9TDfSWu63RMeta } from "/codebuild/output/src3487572758/src/flintrock/nuxtapp/pages/events/index.vue?macro=true";
import { default as indexvH0wuQYfjNMeta } from "/codebuild/output/src3487572758/src/flintrock/nuxtapp/pages/gallery/index.vue?macro=true";
import { default as indexg8zgdIt3xTMeta } from "/codebuild/output/src3487572758/src/flintrock/nuxtapp/pages/homes/[location]/[community]/index.vue?macro=true";
import { default as indexd3Ua0nezd3Meta } from "/codebuild/output/src3487572758/src/flintrock/nuxtapp/pages/homes/plans/[plan]/index.vue?macro=true";
import { default as index0dZBDxrprrMeta } from "/codebuild/output/src3487572758/src/flintrock/nuxtapp/pages/index.vue?macro=true";
import { default as land_45acquisitionbjnfGvLEGRMeta } from "/codebuild/output/src3487572758/src/flintrock/nuxtapp/pages/land-acquisition.vue?macro=true";
import { default as _91testimonial_93cNLu5p2k43Meta } from "/codebuild/output/src3487572758/src/flintrock/nuxtapp/pages/lifestyle/reviews/[testimonial].vue?macro=true";
import { default as indexUrdAHQ3fPfMeta } from "/codebuild/output/src3487572758/src/flintrock/nuxtapp/pages/lifestyle/reviews/category/[category]/index.vue?macro=true";
import { default as indexvW3cW6zWcoMeta } from "/codebuild/output/src3487572758/src/flintrock/nuxtapp/pages/lifestyle/reviews/index.vue?macro=true";
import { default as _91_46_46_46slug_93XoGPbuYzpbMeta } from "/codebuild/output/src3487572758/src/flintrock/nuxtapp/pages/marshallfire/[...slug].vue?macro=true";
import { default as indexSLx1a0EgZKMeta } from "/codebuild/output/src3487572758/src/flintrock/nuxtapp/pages/models/[model]/index.vue?macro=true";
import { default as my_45favoritesZZ5Jdi1S96Meta } from "/codebuild/output/src3487572758/src/flintrock/nuxtapp/pages/my-favorites.vue?macro=true";
import { default as new_45home_45searchIJ3WarlDdvMeta } from "/codebuild/output/src3487572758/src/flintrock/nuxtapp/pages/new-home-search.vue?macro=true";
import { default as communitieshdsD51FBG3Meta } from "/codebuild/output/src3487572758/src/flintrock/nuxtapp/pages/our-homes/communities.vue?macro=true";
import { default as duplex_45collectionHBWMZgtrGzMeta } from "/codebuild/output/src3487572758/src/flintrock/nuxtapp/pages/our-homes/home-collections/duplex-collection.vue?macro=true";
import { default as heritage_45collectionEpBJ9wWIxDMeta } from "/codebuild/output/src3487572758/src/flintrock/nuxtapp/pages/our-homes/home-collections/heritage-collection.vue?macro=true";
import { default as heritagei_45collectionIOhLfIj0nOMeta } from "/codebuild/output/src3487572758/src/flintrock/nuxtapp/pages/our-homes/home-collections/heritagei-collection.vue?macro=true";
import { default as legacy_45collectionNK3L4OZaNiMeta } from "/codebuild/output/src3487572758/src/flintrock/nuxtapp/pages/our-homes/home-collections/legacy-collection.vue?macro=true";
import { default as legacyii_45collectiontwkVYGoWPdMeta } from "/codebuild/output/src3487572758/src/flintrock/nuxtapp/pages/our-homes/home-collections/legacyii-collection.vue?macro=true";
import { default as pioneer_45collectionIt9Yu8XZ4mMeta } from "/codebuild/output/src3487572758/src/flintrock/nuxtapp/pages/our-homes/home-collections/pioneer-collection.vue?macro=true";
import { default as townhome_45collectionQkZxzLJEJMMeta } from "/codebuild/output/src3487572758/src/flintrock/nuxtapp/pages/our-homes/home-collections/townhome-collection.vue?macro=true";
import { default as indexCtKkKnimISMeta } from "/codebuild/output/src3487572758/src/flintrock/nuxtapp/pages/our-homes/index.vue?macro=true";
import { default as privacytermsmiGDjzqo23Meta } from "/codebuild/output/src3487572758/src/flintrock/nuxtapp/pages/privacyterms.vue?macro=true";
import { default as indexTP2ydRiDBhMeta } from "/codebuild/output/src3487572758/src/flintrock/nuxtapp/pages/qmi/[qmi]/index.vue?macro=true";
import { default as sandbox184GSjRHiCMeta } from "/codebuild/output/src3487572758/src/flintrock/nuxtapp/pages/sandbox.vue?macro=true";
import { default as the_45preserveWWv2RhGVhAMeta } from "/codebuild/output/src3487572758/src/flintrock/nuxtapp/pages/the-preserve.vue?macro=true";
import { default as the_45ridge_45at_45knob_45creek4QzGCtnp47Meta } from "/codebuild/output/src3487572758/src/flintrock/nuxtapp/pages/the-ridge-at-knob-creek.vue?macro=true";
export default [
  {
    name: "about-us-financing",
    path: "/about-us/financing",
    component: () => import("/codebuild/output/src3487572758/src/flintrock/nuxtapp/pages/about-us/financing.vue").then(m => m.default || m)
  },
  {
    name: "about-us",
    path: "/about-us",
    component: () => import("/codebuild/output/src3487572758/src/flintrock/nuxtapp/pages/about-us/index.vue").then(m => m.default || m)
  },
  {
    name: "about-us-our-story",
    path: "/about-us/our-story",
    component: () => import("/codebuild/output/src3487572758/src/flintrock/nuxtapp/pages/about-us/our-story.vue").then(m => m.default || m)
  },
  {
    name: "about-us-our-team",
    path: "/about-us/our-team",
    component: () => import("/codebuild/output/src3487572758/src/flintrock/nuxtapp/pages/about-us/our-team.vue").then(m => m.default || m)
  },
  {
    name: "areas-copperas-cove-area",
    path: "/areas/copperas-cove-area",
    component: () => import("/codebuild/output/src3487572758/src/flintrock/nuxtapp/pages/areas/copperas-cove-area.vue").then(m => m.default || m)
  },
  {
    name: "areas-georgetown-area",
    path: "/areas/georgetown-area",
    component: () => import("/codebuild/output/src3487572758/src/flintrock/nuxtapp/pages/areas/georgetown-area.vue").then(m => m.default || m)
  },
  {
    name: "areas-killeen-area",
    path: "/areas/killeen-area",
    component: () => import("/codebuild/output/src3487572758/src/flintrock/nuxtapp/pages/areas/killeen-area.vue").then(m => m.default || m)
  },
  {
    name: "areas-lorena-area",
    path: "/areas/lorena-area",
    component: () => import("/codebuild/output/src3487572758/src/flintrock/nuxtapp/pages/areas/lorena-area.vue").then(m => m.default || m)
  },
  {
    name: "areas-nolanville-area",
    path: "/areas/nolanville-area",
    component: () => import("/codebuild/output/src3487572758/src/flintrock/nuxtapp/pages/areas/nolanville-area.vue").then(m => m.default || m)
  },
  {
    name: "areas-salado-area",
    path: "/areas/salado-area",
    component: () => import("/codebuild/output/src3487572758/src/flintrock/nuxtapp/pages/areas/salado-area.vue").then(m => m.default || m)
  },
  {
    name: "areas-temple-area",
    path: "/areas/temple-area",
    component: () => import("/codebuild/output/src3487572758/src/flintrock/nuxtapp/pages/areas/temple-area.vue").then(m => m.default || m)
  },
  {
    name: "areas-troy-area",
    path: "/areas/troy-area",
    component: () => import("/codebuild/output/src3487572758/src/flintrock/nuxtapp/pages/areas/troy-area.vue").then(m => m.default || m)
  },
  {
    name: "blog-post",
    path: "/blog/:post()",
    component: () => import("/codebuild/output/src3487572758/src/flintrock/nuxtapp/pages/blog/[post].vue").then(m => m.default || m)
  },
  {
    name: "blog-category-category",
    path: "/blog/category/:category()",
    component: () => import("/codebuild/output/src3487572758/src/flintrock/nuxtapp/pages/blog/category/[category]/index.vue").then(m => m.default || m)
  },
  {
    name: "blog",
    path: "/blog",
    component: () => import("/codebuild/output/src3487572758/src/flintrock/nuxtapp/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: "campaigns-slug",
    path: "/campaigns/:slug(.*)*",
    meta: _91_46_46_46slug_93T0pWupx7jIMeta || {},
    component: () => import("/codebuild/output/src3487572758/src/flintrock/nuxtapp/pages/campaigns/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "campaigns",
    path: "/campaigns",
    component: () => import("/codebuild/output/src3487572758/src/flintrock/nuxtapp/pages/campaigns/index.vue").then(m => m.default || m)
  },
  {
    name: "coming-soon-park-meadows",
    path: "/coming-soon/park-meadows",
    component: () => import("/codebuild/output/src3487572758/src/flintrock/nuxtapp/pages/coming-soon/park-meadows.vue").then(m => m.default || m)
  },
  {
    name: "coming-soon-rosenthal-estates",
    path: "/coming-soon/rosenthal-estates",
    component: () => import("/codebuild/output/src3487572758/src/flintrock/nuxtapp/pages/coming-soon/rosenthal-estates.vue").then(m => m.default || m)
  },
  {
    name: "coming-soon-template",
    path: "/coming-soon/template",
    component: () => import("/codebuild/output/src3487572758/src/flintrock/nuxtapp/pages/coming-soon/template.vue").then(m => m.default || m)
  },
  {
    name: "communities-community",
    path: "/communities/:community()",
    meta: indexY0zVfSSK4qMeta || {},
    component: () => import("/codebuild/output/src3487572758/src/flintrock/nuxtapp/pages/communities/[community]/index.vue").then(m => m.default || m)
  },
  {
    name: "contact-us-ask-a-question",
    path: "/contact-us/ask-a-question",
    component: () => import("/codebuild/output/src3487572758/src/flintrock/nuxtapp/pages/contact-us/ask-a-question.vue").then(m => m.default || m)
  },
  {
    name: "contact-us-career-opportunities",
    path: "/contact-us/career-opportunities",
    component: () => import("/codebuild/output/src3487572758/src/flintrock/nuxtapp/pages/contact-us/career-opportunities.vue").then(m => m.default || m)
  },
  {
    name: "contact-us-faq",
    path: "/contact-us/faq",
    component: () => import("/codebuild/output/src3487572758/src/flintrock/nuxtapp/pages/contact-us/faq.vue").then(m => m.default || m)
  },
  {
    name: "contact-us-homeowner-resources",
    path: "/contact-us/homeowner-resources",
    component: () => import("/codebuild/output/src3487572758/src/flintrock/nuxtapp/pages/contact-us/homeowner-resources.vue").then(m => m.default || m)
  },
  {
    name: "contact-us",
    path: "/contact-us",
    component: () => import("/codebuild/output/src3487572758/src/flintrock/nuxtapp/pages/contact-us/index.vue").then(m => m.default || m)
  },
  {
    name: "contact-us-our-locations",
    path: "/contact-us/our-locations",
    component: () => import("/codebuild/output/src3487572758/src/flintrock/nuxtapp/pages/contact-us/our-locations.vue").then(m => m.default || m)
  },
  {
    name: "contact-us-trade-partner-inquiry",
    path: "/contact-us/trade-partner-inquiry",
    component: () => import("/codebuild/output/src3487572758/src/flintrock/nuxtapp/pages/contact-us/trade-partner-inquiry.vue").then(m => m.default || m)
  },
  {
    name: "current-offers",
    path: "/current-offers",
    component: () => import("/codebuild/output/src3487572758/src/flintrock/nuxtapp/pages/current-offers.vue").then(m => m.default || m)
  },
  {
    name: "events-event",
    path: "/events/:event()",
    component: () => import("/codebuild/output/src3487572758/src/flintrock/nuxtapp/pages/events/[event]/index.vue").then(m => m.default || m)
  },
  {
    name: "events",
    path: "/events",
    component: () => import("/codebuild/output/src3487572758/src/flintrock/nuxtapp/pages/events/index.vue").then(m => m.default || m)
  },
  {
    name: "gallery",
    path: "/gallery",
    component: () => import("/codebuild/output/src3487572758/src/flintrock/nuxtapp/pages/gallery/index.vue").then(m => m.default || m)
  },
  {
    name: "homes-location-community",
    path: "/homes/:location()/:community()",
    meta: indexg8zgdIt3xTMeta || {},
    component: () => import("/codebuild/output/src3487572758/src/flintrock/nuxtapp/pages/homes/[location]/[community]/index.vue").then(m => m.default || m)
  },
  {
    name: "homes-plans-plan",
    path: "/homes/plans/:plan()",
    component: () => import("/codebuild/output/src3487572758/src/flintrock/nuxtapp/pages/homes/plans/[plan]/index.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/codebuild/output/src3487572758/src/flintrock/nuxtapp/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "land-acquisition",
    path: "/land-acquisition",
    component: () => import("/codebuild/output/src3487572758/src/flintrock/nuxtapp/pages/land-acquisition.vue").then(m => m.default || m)
  },
  {
    name: "lifestyle-reviews-testimonial",
    path: "/lifestyle/reviews/:testimonial()",
    component: () => import("/codebuild/output/src3487572758/src/flintrock/nuxtapp/pages/lifestyle/reviews/[testimonial].vue").then(m => m.default || m)
  },
  {
    name: "lifestyle-reviews-category-category",
    path: "/lifestyle/reviews/category/:category()",
    component: () => import("/codebuild/output/src3487572758/src/flintrock/nuxtapp/pages/lifestyle/reviews/category/[category]/index.vue").then(m => m.default || m)
  },
  {
    name: "lifestyle-reviews",
    path: "/lifestyle/reviews",
    component: () => import("/codebuild/output/src3487572758/src/flintrock/nuxtapp/pages/lifestyle/reviews/index.vue").then(m => m.default || m)
  },
  {
    name: "marshallfire-slug",
    path: "/marshallfire/:slug(.*)*",
    component: () => import("/codebuild/output/src3487572758/src/flintrock/nuxtapp/pages/marshallfire/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "models-model",
    path: "/models/:model()",
    component: () => import("/codebuild/output/src3487572758/src/flintrock/nuxtapp/pages/models/[model]/index.vue").then(m => m.default || m)
  },
  {
    name: "my-favorites",
    path: "/my-favorites",
    component: () => import("/codebuild/output/src3487572758/src/flintrock/nuxtapp/pages/my-favorites.vue").then(m => m.default || m)
  },
  {
    name: "new-home-search",
    path: "/new-home-search",
    component: () => import("/codebuild/output/src3487572758/src/flintrock/nuxtapp/pages/new-home-search.vue").then(m => m.default || m)
  },
  {
    name: "our-homes-communities",
    path: "/our-homes/communities",
    alias: ["/our-homes/model-homes/","/our-homes/quick-move-in-homes/"],
    component: () => import("/codebuild/output/src3487572758/src/flintrock/nuxtapp/pages/our-homes/communities.vue").then(m => m.default || m)
  },
  {
    name: "our-homes-home-collections-duplex-collection",
    path: "/our-homes/home-collections/duplex-collection",
    component: () => import("/codebuild/output/src3487572758/src/flintrock/nuxtapp/pages/our-homes/home-collections/duplex-collection.vue").then(m => m.default || m)
  },
  {
    name: "our-homes-home-collections-heritage-collection",
    path: "/our-homes/home-collections/heritage-collection",
    component: () => import("/codebuild/output/src3487572758/src/flintrock/nuxtapp/pages/our-homes/home-collections/heritage-collection.vue").then(m => m.default || m)
  },
  {
    name: "our-homes-home-collections-heritagei-collection",
    path: "/our-homes/home-collections/heritagei-collection",
    component: () => import("/codebuild/output/src3487572758/src/flintrock/nuxtapp/pages/our-homes/home-collections/heritagei-collection.vue").then(m => m.default || m)
  },
  {
    name: "our-homes-home-collections-legacy-collection",
    path: "/our-homes/home-collections/legacy-collection",
    component: () => import("/codebuild/output/src3487572758/src/flintrock/nuxtapp/pages/our-homes/home-collections/legacy-collection.vue").then(m => m.default || m)
  },
  {
    name: "our-homes-home-collections-legacyii-collection",
    path: "/our-homes/home-collections/legacyii-collection",
    component: () => import("/codebuild/output/src3487572758/src/flintrock/nuxtapp/pages/our-homes/home-collections/legacyii-collection.vue").then(m => m.default || m)
  },
  {
    name: "our-homes-home-collections-pioneer-collection",
    path: "/our-homes/home-collections/pioneer-collection",
    component: () => import("/codebuild/output/src3487572758/src/flintrock/nuxtapp/pages/our-homes/home-collections/pioneer-collection.vue").then(m => m.default || m)
  },
  {
    name: "our-homes-home-collections-townhome-collection",
    path: "/our-homes/home-collections/townhome-collection",
    component: () => import("/codebuild/output/src3487572758/src/flintrock/nuxtapp/pages/our-homes/home-collections/townhome-collection.vue").then(m => m.default || m)
  },
  {
    name: "our-homes",
    path: "/our-homes",
    component: () => import("/codebuild/output/src3487572758/src/flintrock/nuxtapp/pages/our-homes/index.vue").then(m => m.default || m)
  },
  {
    name: "privacyterms",
    path: "/privacyterms",
    component: () => import("/codebuild/output/src3487572758/src/flintrock/nuxtapp/pages/privacyterms.vue").then(m => m.default || m)
  },
  {
    name: "qmi-qmi",
    path: "/qmi/:qmi()",
    component: () => import("/codebuild/output/src3487572758/src/flintrock/nuxtapp/pages/qmi/[qmi]/index.vue").then(m => m.default || m)
  },
  {
    name: "sandbox",
    path: "/sandbox",
    component: () => import("/codebuild/output/src3487572758/src/flintrock/nuxtapp/pages/sandbox.vue").then(m => m.default || m)
  },
  {
    name: "the-preserve",
    path: "/the-preserve",
    component: () => import("/codebuild/output/src3487572758/src/flintrock/nuxtapp/pages/the-preserve.vue").then(m => m.default || m)
  },
  {
    name: "the-ridge-at-knob-creek",
    path: "/the-ridge-at-knob-creek",
    component: () => import("/codebuild/output/src3487572758/src/flintrock/nuxtapp/pages/the-ridge-at-knob-creek.vue").then(m => m.default || m)
  }
]