import revive_payload_client_4sVQNw7RlN from "/codebuild/output/src3487572758/src/flintrock/nuxtapp/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/codebuild/output/src3487572758/src/flintrock/nuxtapp/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/codebuild/output/src3487572758/src/flintrock/nuxtapp/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/codebuild/output/src3487572758/src/flintrock/nuxtapp/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/codebuild/output/src3487572758/src/flintrock/nuxtapp/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/codebuild/output/src3487572758/src/flintrock/nuxtapp/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/codebuild/output/src3487572758/src/flintrock/nuxtapp/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_A0OWXRrUgq from "/codebuild/output/src3487572758/src/flintrock/nuxtapp/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/codebuild/output/src3487572758/src/flintrock/nuxtapp/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/codebuild/output/src3487572758/src/flintrock/nuxtapp/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_eTVJQYlCmx from "/codebuild/output/src3487572758/src/flintrock/nuxtapp/node_modules/@nuxtjs/apollo/dist/runtime/plugin.mjs";
import apolloErrorHandler_k0djdHqTRD from "/codebuild/output/src3487572758/src/flintrock/nuxtapp/plugins/apolloErrorHandler.ts";
import apollo_1ILFkzpYSr from "/codebuild/output/src3487572758/src/flintrock/nuxtapp/plugins/apollo.ts";
import sentry_3AyO8nEfhE from "/codebuild/output/src3487572758/src/flintrock/nuxtapp/plugins/sentry.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_eTVJQYlCmx,
  apolloErrorHandler_k0djdHqTRD,
  apollo_1ILFkzpYSr,
  sentry_3AyO8nEfhE
]